import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PostCard from "../components/post-card/post-card"
import SEO from "../components/seo"
import { TagPostsWrapper, TagPageHeading, TagName } from "./templates.style"

const Tags = ({ pageContext, data }: any) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allGhostPost

  return (
    <Layout>
      <SEO title={tag} description={`A collection of ${totalCount} post`} />
      <style dangerouslySetInnerHTML={{ __html: `
        @media (min-width: 990px) {
          .tag-list {
            display: flex;
          }
          .tag-list .post_details {
            display: inline-block;
            width: 70%;
            padding-left: 2%;
          }
          .tag-list .post_preview {
            display: inline-block;
            width: 30%;
            margin-bottom: 0;
          }
        }
      `}} />

      <TagPostsWrapper>
        <TagPageHeading>
          <TagName>{tag}</TagName>
          {`A collection of ${totalCount} post`}
        </TagPageHeading>
        {edges.map(({ node, index }: any) => (
          <PostCard
            key={node.slug}
            title={node.title}
            url={`/${node.slug}`}
            className="tag-list"
            image={
              node.feature_image_sharp == null
                ? null
                : node.feature_image_sharp.childImageSharp.fluid
            }
            description={node.meta_description || node.excerpt}
            // date={node.published_at}
            tags={node.tags}
          />
        ))}
      </TagPostsWrapper>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allGhostPost(
      filter: {
        slug: {ne: "data-schema" }
        tags: {elemMatch: {slug: {eq: $tag}}}
      },
      limit: 2000,
      sort: {fields: published_at, order: DESC}
      ){
      totalCount
      edges {
        node {
          excerpt
          slug
          title
          tags {
            name
            slug
          }
          feature_image_sharp {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1170, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          meta_description
          published_at(formatString: "DD [<span>] MMMM [</span>]")
        }
      }
    }
  }
`